import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputSelect", "hiddenInput"];

  connect() {
    this.initializeSelectPicker();
    this.monitorSearchInput();
    this.inputSelectTarget.addEventListener("change", this.handleSelectionChange.bind(this));
  }

  /**
   * Adds the select picker class to the target element and refreshes its UI.
   */
  initializeSelectPicker() {
    $(this.inputSelectTarget).selectpicker(); 
  }

  /**
   * Monitors the search input for real-time user input.
   */
  monitorSearchInput() {
    $(this.inputSelectTarget).on('shown.bs.select', () => {
      this._attachSearchInputListener();
    });

    $(this.inputSelectTarget).on('refreshed.bs.select', () => {
      this._attachSearchInputListener();
    });
  }

  /**
   * @function _attachSearchInputListener
   * @description Attaches an `input` event listener to the Bootstrap SelectPicker search field.
   * Ensures that the event listener remains bound to the correct Stimulus controller instance.
   * If the search input does not exist, logs an error and waits for future mutations.
   *
   * @private
   *
   * @example
   * // Automatically attaches the event listener when called
   * this._attachSearchInputListener();
   *
   * @throws {Error} Logs an error if the search input field cannot be found.
   *
   * @see monitorSearchInput
   */
  _attachSearchInputListener() {
    const searchInput = this._getSearchInput();
    if (searchInput) {
      searchInput.removeEventListener("input", this.boundHandleSearchInput);
      this.boundHandleSearchInput = this.handleSearchInput.bind(this);
      searchInput.addEventListener("input", this.boundHandleSearchInput);
    } else {
      console.error("Search input not found! Waiting for mutation...");
    }
  }

  /*
   *
   * Handles input changes in the search box, processes the search value,
   * and renders a new dropdown option if applicable.
   *
   * @param {Event} event - The input event from the search box.
  */
  handleSearchInput(event) {
    const searchValue = event.target.value.trim();
    this.renderNewOption(searchValue);
  }

  /**
   * Renders a new option in the dropdown based on the provided value.
   * Validates the value to determine if it should be selectable or highlighted as invalid.
   *
   * @param {string} value - The value to add as a dropdown option.
   */
  renderNewOption(value) {
    const existingCustomOption = this.inputSelectTarget.querySelector('option[data-custom="true"]');
    if (existingCustomOption) existingCustomOption.remove();

    const existingValues = Array.from(this.inputSelectTarget.options).map((opt) => opt.value);
    if (existingValues.includes(value)) return;

    const newOption = document.createElement("option");
    newOption.value = value;

    if (this._isValidEmail(value)) {
      newOption.textContent = `Add "${value}"`;
      newOption.dataset.valid = "true";
      newOption.classList.remove("text-danger", "bg-light");
      newOption.disabled = false;
    } else {
      newOption.textContent = `"${value}" is not a valid email address`;
      newOption.dataset.valid = "false";
      newOption.classList.add("text-danger", "bg-light");
      newOption.disabled = true;
    }

    newOption.dataset.custom = "true";
    this.inputSelectTarget.prepend(newOption);
    this._refreshSelectPicker();
  }

  /**
   * Handles changes in dropdown selection, validates custom options,
   * and updates the hidden input fields accordingly.
   */
  handleSelectionChange() {
    const customOption = this.inputSelectTarget.querySelector('option[data-custom="true"]');
    if (customOption && customOption.selected) {
      const customValue = customOption.value;
      if (customOption.dataset.valid === "true") {
        this._hideErrorMessage();
        this.addNewOption(customValue);
      } else {
        this._showErrorMessage(`"${customValue}" is not a valid email address.`);
        customOption.selected = false;
      }
    }

    this._updateHiddenInputs();
  }

  /**
   * Adds a new valid option to the dropdown and updates the hidden input fields.
   *
   * @param {string} value - The value to be added as a valid option.
   */
  addNewOption(value) {
    const option = this.inputSelectTarget.querySelector('option[data-custom="true"]');
    if (option) option.remove();

    const newOption = new Option(value, value, true, true);
    this.inputSelectTarget.add(newOption);

    this._refreshSelectPicker();
    this._updateHiddenInputs();
  }

  // Private Methods

  _refreshSelectPicker() {
    $(this.inputSelectTarget).selectpicker("refresh");
  }

  _getSearchInput() {
    return this.inputSelectTarget.parentElement.querySelector(".bs-searchbox input");
  }

  _debouncedSearchInputHandler() {
    return this._debounce((event) => this.handleSearchInput(event), 300);
  }

  _updateHiddenInputs() {
    const selectedValues = Array.from(this.inputSelectTarget.selectedOptions).map((opt) => opt.value);
    this.hiddenInputTarget.innerHTML = "";

    selectedValues.forEach((value) => {
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = "input[]";
      input.value = value;
      this.hiddenInputTarget.append(input);
    });
  }

  _isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
  }

  _showErrorMessage(message) {
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("invalid-feedback", "d-block");
    errorContainer.textContent = message;

    if (!this.inputSelectTarget.parentElement.querySelector(".invalid-feedback")) {
      this.inputSelectTarget.parentElement.append(errorContainer);
    }
  }

  _hideErrorMessage() {
    const errorContainer = this.inputSelectTarget.parentElement.querySelector(".invalid-feedback");
    if (errorContainer) {
      errorContainer.remove();
    }
  }

  _debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }
}

